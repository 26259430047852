import { Box, Typography } from '@mui/material'
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import ja from 'date-fns/locale/ja' // eslint-disable-line import/no-duplicates
import React from 'react'

import { IndividualLessonKarteContainer } from '@/components/pages/index/IndividualLessonKarteContainer'
import { LessonKarteContainer } from '@/components/pages/index/LessonKarteContainer'
import { RestKarteContainer } from '@/components/pages/index/RestKarteContainer'
import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'
import { Karte, karteContentSwitcher } from '@/models/Karte'
import { LessonKarte } from '@/models/LessonKarte'
import { RestKarte } from '@/models/RestKarte'

export const KarteComponent: React.VFC<{
  karte: Karte
  hasPrev: boolean
  hasNext: boolean
  hasCommentAtPrev: boolean
  hasCommentAtNext: boolean
  afterSubmit: () => void
  readonly: boolean
}> = ({
  karte,
  afterSubmit,
  hasPrev,
  hasNext,
  hasCommentAtPrev,
  hasCommentAtNext,
  readonly
}) => {
  const lessonKarteElement = (content: LessonKarte) => (
    <LessonKarteContainer lessonKarte={content} handleSubmit={afterSubmit} readonly={readonly}/>
  )

  const individualLessonKarteElement = (content: IndividualLessonKarte) => (
    <IndividualLessonKarteContainer karte={content} />
  )

  const restKarteElement = (content: RestKarte) => <RestKarteContainer karte={content} />

  const [appHeight, setAppHeight] = React.useState(window.innerHeight - 156)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight - 156)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight - 156)
  })

  const arrowStyle = {
    position: 'relative',
    top: '0.5rem',
    mx: 1,
    width: '1rem',
    fontSize: '0.5rem',
    color: '#8BCC00',
  }

  const commentNoticeStyle = {
    color: '#ff7575',
    display: 'inline',
    position: 'absolute',
    top: '-15px',
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'center',
        }}
      >
        <Typography variant="body1" sx={arrowStyle}>
          {hasPrev ? '◀︎' : ' '}
          {hasCommentAtPrev && <Typography sx={commentNoticeStyle}>●</Typography>}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {format(new Date(karte.lessonDate + 'T00:00:00'), 'M月d日(E)', { locale: ja })}
        </Typography>
        <Typography variant="body1" sx={arrowStyle}>
          {hasNext ? '▶︎' : ''}
          {hasCommentAtNext && <Typography sx={commentNoticeStyle}>●</Typography>}
        </Typography>
      </Box>
      <Box sx={{ height: `${appHeight}px` }}>
        {karteContentSwitcher(karte, lessonKarteElement, individualLessonKarteElement, restKarteElement)}
      </Box>
    </>
  )
}
