import { Typography } from '@mui/material'
import React from 'react'

export const InputTextCounter: React.VFC<{
  max: number
  text: string
}> = ({ max, text }) => {
  const remain: number = max - text.length
  return (
    <Typography variant="body1" sx={{ textAlign: 'right', fontSize: '0.7rem', color: remain >= 0 ? 'black' : 'red' }}>
      ※あと{remain}文字入力可能です
    </Typography>
  )
}
