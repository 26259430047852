import { Cancel, NoPhotography, PhotoCamera } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useReward } from 'react-rewards'
import { toast } from 'react-toastify'

import { BreakLineBox } from '@/components/common/BreakLineBox'
import { Comment } from '@/components/common/Comment'
import { InputTextCounter } from '@/components/common/InputTextCounter'
import { SubmitButtonWithEvent } from '@/components/common/SubmitButtonWithEvent'
import { ChallengeList } from '@/components/pages/index/ChallengeList'
import { ImageModal } from '@/components/pages/index/ImageModal'
import { axiosContext } from '@/context/axios'
import { LessonKarte } from '@/models/LessonKarte'
import { LessonKarteCondition } from '@/models/LessonKarteCondition'
import { LessonKarteStatus } from '@/models/LessonKarteStatus'

const conditionEmoji = (condition: LessonKarteCondition | null) => {
  if (condition === 'good') {
    return '😄'
  } else if (condition === 'normal') {
    return '😐'
  } else if (condition === 'bad') {
    return '🥲'
  } else {
    return ''
  }
}

const maxImagesUpload = 3

export const LessonKarteContainer: React.VFC<{
  lessonKarte: LessonKarte
  handleSubmit: () => void
  readonly: boolean
}> = ({ lessonKarte, handleSubmit, readonly }) => {
  const { axiosInstance } = useContext(axiosContext)
  const [cookies, setCookie, removeCookie] = useCookies(['doneSorobanKartes', 'doneAnzanKartes'])

  const rewardId: string = 'reward_' + lessonKarte.id.toString()
  const { reward } = useReward(rewardId, 'confetti', { zIndex: 1000 })

  const [karteFormValues, setKarteFormValues] = useState<karteFormValues>({
    sorobanDones: lessonKarte.filledAt
      ? lessonKarte.sorobanChallengeList.filter((item) => item.isDone).map((item) => item.id)
      : cookies.doneSorobanKartes ?? [],
    anzanDones: lessonKarte.filledAt
      ? lessonKarte.anzanChallengeList.filter((item) => item.isDone).map((item) => item.id)
      : cookies.doneAnzanKartes ?? [],
    progress: lessonKarte.progress ?? '',
    time: lessonKarte.time?.toString() ?? '',
    condition: lessonKarte.condition ?? '',
    detailedCondition: lessonKarte.detailedCondition ?? '',
    parentCondition: lessonKarte.parentCondition ?? '',
    problems: lessonKarte.problems ?? '',
  })
  const [files, setFiles] = useState<{ [x: string]: File }>({})
  const [selectedFileKey, setSelectedFileKey] = useState<string | null>(null)

  const [errorMessages, setErrorMessages] = useState<{ [x: string]: string }>({})

  const isAllChallengeDone: boolean =
    lessonKarte.sorobanChallengeList.length === karteFormValues.sorobanDones.length &&
    lessonKarte.anzanChallengeList.length === karteFormValues.anzanDones.length

  const [isEditMode, setIsEditMode] = useState<boolean>(
    readonly ? false : (lessonKarte.status as LessonKarteStatus) === LessonKarteStatus.Notyet
  )

  const getFilesFromS3 = async (fileKeys: string[]) => {
    for (const fileKey of fileKeys) {
      const { data: url } = await axiosInstance.get(`/api/s3/presignedUrlForGetObject/${fileKey}`)
      const { data: file } = await axiosInstance.get(url, { responseType: 'blob' })
      setFiles((prev) => ({ ...prev, [fileKey]: file as File }))
    }
  }

  useEffect(() => {
    getFilesFromS3(lessonKarte.fileKeys)
  }, [])

  const handleChange = (name: string, value: string) => {
    setKarteFormValues((prev) => ({ ...prev, [name]: value }))
    setErrorMessages((prev) => {
      delete prev[name]
      return prev
    })
  }

  const handleSorobanChallengeClick = (value: number) => {
    let newSorobanDones = [] as number[]
    if (karteFormValues.sorobanDones.includes(value)) {
      newSorobanDones = karteFormValues.sorobanDones.filter((item) => item !== value)
      setKarteFormValues((prev) => ({
        ...prev,
        ['sorobanDones']: newSorobanDones,
      }))
    } else {
      newSorobanDones = [...karteFormValues.sorobanDones, value]
      setKarteFormValues((prev) => ({ ...prev, ['sorobanDones']: newSorobanDones }))
    }

    setCookie('doneSorobanKartes', newSorobanDones, { maxAge: 60 * 60 * 24 * 365 })
  }

  const handleAnzanChallengeClick = (value: number) => {
    let newAnzanDones = [] as number[]
    if (karteFormValues.anzanDones.includes(value)) {
      newAnzanDones = karteFormValues.anzanDones.filter((item) => item !== value)
      setKarteFormValues((prev) => ({
        ...prev,
        ['anzanDones']: newAnzanDones,
      }))
    } else {
      newAnzanDones = [...karteFormValues.anzanDones, value]
      setKarteFormValues((prev) => ({ ...prev, ['anzanDones']: newAnzanDones }))
    }

    setCookie('doneAnzanKartes', newAnzanDones, { maxAge: 60 * 60 * 24 * 365 })
  }

  const submit = async (e: React.MouseEvent) => {
    try {
      if (karteFormValues.time === '0') {
        await axiosInstance.post('/api/karte/update_with_rest', {
          id: lessonKarte.id,
          problems: karteFormValues.problems,
        })
      } else {
        await axiosInstance
          .post('/api/karte/update_after_lesson', {
            id: lessonKarte.id,
            completedSorobanChallengeIdList: karteFormValues.sorobanDones,
            completedAnzanChallengeIdList: karteFormValues.anzanDones,
            progress: karteFormValues.progress,
            time: parseInt(karteFormValues.time),
            condition: karteFormValues.condition,
            detailedCondition: karteFormValues.detailedCondition,
            parentCondition: karteFormValues.parentCondition,
            problems: karteFormValues.problems,
            fileKeys: Object.keys(files),
          })
          .then(() => {
            if (isAllChallengeDone) {
              const span = document.createElement('span')
              span.style.position = 'fixed'
              span.style.top = e.clientY + 'px'
              span.style.left = e.clientX + 'px'
              span.id = rewardId
              document.body.appendChild(span)
              reward()
            }
            removeCookie('doneSorobanKartes')
            removeCookie('doneAnzanKartes')
          })
      }

      handleSubmit()
      setIsEditMode(false)
      toast.success('投稿しました。')
    } catch (err: any) {
      setErrorMessages(err.response.data.errors)
    }
  }

  const hasError = (name: string) => Object.keys(errorMessages).indexOf(name) !== -1

  const window_bottom_offset = 138
  const [appHeight, setAppHeight] = React.useState(window.innerHeight - window_bottom_offset)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight - window_bottom_offset)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight - window_bottom_offset)
  })

  const isRest: boolean = karteFormValues.time === '0'

  const isStillEditable: boolean = readonly ? false : [LessonKarteStatus.Notyet, LessonKarteStatus.Posted].includes(
    lessonKarte.status as LessonKarteStatus
  )

  const submitBtn = (): JSX.Element => {
    if (!isStillEditable) {
      return <Box></Box>
    }

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: 2,
        }}
      >
        {lessonKarte.filledAt && <Button onClick={() => setIsEditMode(false)}>もどる</Button>}
        <SubmitButtonWithEvent onClick={submit}>投稿</SubmitButtonWithEvent>
      </Box>
    )
  }

  const handleOnAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const { data } = await axiosInstance.get('/api/s3/presignedUrlForPutObject')

    setFiles((prev) => ({ ...prev, [data.key]: file as File }))

    await axiosInstance.put(data.url, file, {
      headers: { 'Content-Type': file.type },
    })
  }

  const handleOnRemoveImage = (fileKey: string) => {
    const newFiles = { ...files }
    delete newFiles[fileKey]

    setFiles(newFiles)
  }

  const handleModalClose = () => {
    setSelectedFileKey(null)
  }

  return (
    <Box className={'scrollableY'} sx={{ maxHeight: `${appHeight}px` }}>
      {lessonKarte.comment && (
        <ListItem sx={{ display: 'block', px: 0, mb: 3 }}>
          <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold', fontSize: '0.9rem' }}>
            トレーナーからのコメント
          </Typography>
          <Comment>{lessonKarte.comment}</Comment>
        </ListItem>
      )}
      {lessonKarte.sorobanChallengeList.length !== 0 && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            py: 1,
            boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 10%)',
            mb: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.2rem',
              color: '#449B00',
              fontWeight: 'bold',
              verticalAlign: 'middle',
              display: 'flex',
              justifyContent: 'center',
              bgcolor: '#F0FDE6',
              mb: 1,
            }}
          >
            <img src="/images/icon_soroban_challenge.svg" />
            そろばんチャレンジ
          </Typography>
          <ChallengeList
            tasks={lessonKarte.sorobanChallengeList}
            dones={karteFormValues.sorobanDones}
            handleClick={handleSorobanChallengeClick}
            isEditable={isEditMode}
            color={'green'}
          />
        </Box>
      )}
      {lessonKarte.anzanChallengeList.length !== 0 && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            py: 1,
            boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 10%)',
            mb: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.2rem',
              color: '#FF8717',
              fontWeight: 'bold',
              verticalAlign: 'middle',
              display: 'flex',
              justifyContent: 'center',
              bgcolor: '#FFF4E8',
              mb: 1,
            }}
          >
            <img src="/images/icon_anzan_challenge.svg" />
            あんざんチャレンジ
          </Typography>
          <ChallengeList
            tasks={lessonKarte.anzanChallengeList}
            dones={karteFormValues.anzanDones}
            handleClick={handleAnzanChallengeClick}
            isEditable={isEditMode}
            color={'orange'}
          />
        </Box>
      )}
      {(() => {
        if (isEditMode) {
          return (
            <Box sx={{ bgcolor: '#fff', p: 1, fontSize: '1rem' }}>
              <Typography sx={[karteHead, { textAlign: 'center', my: 1 }]}>- カルテ記入 -</Typography>
              <List>
                <ListItem sx={{ px: 0 }}>
                  <Typography sx={karteHead}>レッスン時間</Typography>
                  <FormControl error={hasError('time')}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="time"
                      value={karteFormValues.time}
                      autoWidth
                      displayEmpty
                      onChange={(e) => handleChange(e.target.name, e.target.value)}
                      sx={{
                        height: '2em',
                        fontSize: '0.8rem',
                        ml: 2,
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {[5, 10, 15, 25, 30].map((time) => {
                        return (
                          <MenuItem key={time} value={time.toString()}>
                            {time + '分'}
                          </MenuItem>
                        )
                      })}
                      <MenuItem value={'0'}>お休み</MenuItem>
                    </Select>
                    {hasError('time') && <FormHelperText error>{errorMessages['time']}</FormHelperText>}
                  </FormControl>
                </ListItem>
                {!isRest && (
                  <>
                    <ListItem
                      sx={{
                        px: 0,
                        display: 'block',
                      }}
                    >
                      <Typography sx={karteHead}>進捗</Typography>
                      <TextField
                        InputProps={{
                          sx: {
                            fontSize: '1rem',
                          },
                        }}
                        id="outlined-multiline-flexible"
                        fullWidth
                        multiline
                        rows={5}
                        name="progress"
                        value={karteFormValues.progress}
                        placeholder="チャレンジが途中までしか進なかった場合や予定にない問題までできた場合は記入にしてください。（例：P.10 おけいこ1.2問目まで）"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                      />
                      <InputTextCounter max={1000} text={karteFormValues.progress} />
                    </ListItem>
                    <ListItem sx={{ px: 0, display: 'block' }}>
                      <Typography sx={karteHead}>お子さんの様子</Typography>
                      <ToggleButtonGroup
                        value={karteFormValues.condition}
                        exclusive
                        onChange={(_, value) => handleChange('condition', value)}
                        aria-label="text alignment"
                        sx={{ height: '2.5rem', width: '100%' }}
                      >
                        <ToggleButton
                          color="primary"
                          value="good"
                          aria-label="good"
                          sx={{
                            fontSize: '1.3rem',
                            px: 4,
                            width: '34%',
                            ...(hasError('condition') && { borderColor: 'error.main' }),
                          }}
                        >
                          😄
                        </ToggleButton>
                        <ToggleButton
                          color="primary"
                          value="normal"
                          aria-label="normal"
                          sx={{
                            fontSize: '1.3rem',
                            px: 4,
                            width: '34%',
                            ...(hasError('condition') && { borderColor: 'error.main' }),
                          }}
                        >
                          😐
                        </ToggleButton>
                        <ToggleButton
                          color="primary"
                          value="bad"
                          aria-label="bad"
                          sx={{
                            fontSize: '1.3rem',
                            px: 4,
                            width: '34%',
                            ...(hasError('condition') && { borderColor: 'error.main' }),
                          }}
                        >
                          🥲
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {hasError('condition') && <FormHelperText error>{errorMessages['condition']}</FormHelperText>}
                      <FormControl sx={{ mt: 1, width: '100%' }} error={hasError('detailedCondition')}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="detailedCondition"
                          value={karteFormValues.detailedCondition}
                          autoWidth
                          displayEmpty
                          placeholder="選択してください"
                          onChange={(e) => handleChange(e.target.name, e.target.value)}
                          sx={{
                            height: '2em',
                            fontSize: '0.8rem',
                          }}
                        >
                          <MenuItem value="">選択してください</MenuItem>
                          {[
                            '楽しくできた',
                            '詰まるポイントがあったが楽しくできた',
                            '詰まるポイントがあって気分が乗らなくなった',
                            '集中できずやる気がおきなかった',
                            '難しくて途中でやめた',
                          ].map((value, index) => {
                            return (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {hasError('detailedCondition') && (
                          <FormHelperText error>{errorMessages['detailedCondition']}</FormHelperText>
                        )}
                      </FormControl>
                    </ListItem>
                    <ListItem sx={{ px: 0, display: 'block' }}>
                      <Typography sx={karteHead}>お母さん／お父さんの様子</Typography>
                      <FormControl sx={{ m: 0, width: '100%' }} error={hasError('parentCondition')}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="parentCondition"
                          value={karteFormValues.parentCondition}
                          autoWidth
                          displayEmpty
                          onChange={(e) => handleChange(e.target.name, e.target.value)}
                          sx={{
                            height: '2em',
                            fontSize: '0.8rem',
                          }}
                        >
                          <MenuItem value="">選択してください</MenuItem>
                          {[
                            '子供と楽しくできた',
                            'いつも通りできた',
                            '教え方で不安なところがあった',
                            '少し教えるのに疲れが溜まってきた',
                            '子供をやる気にさせるのに疲れが溜まってきた',
                          ].map((value, index) => {
                            return (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {hasError('parentCondition') && (
                          <FormHelperText error>{errorMessages['parentCondition']}</FormHelperText>
                        )}
                      </FormControl>
                    </ListItem>
                  </>
                )}
                <ListItem
                  sx={{
                    px: 0,
                    display: 'block',
                  }}
                >
                  <Typography sx={karteHead}>困りごと</Typography>
                  <TextField
                    InputProps={{
                      sx: {
                        fontSize: '1rem',
                      },
                    }}
                    id="outlined-multiline-flexible"
                    fullWidth
                    multiline
                    rows={4}
                    name="problems"
                    value={karteFormValues.problems}
                    placeholder="あれば記入してください。"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                  />
                  <InputTextCounter max={1000} text={karteFormValues.problems} />
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                      key={-1}
                      style={{
                        position: 'relative',
                        width: '30px',
                        margin: '0 10px',
                      }}
                    >
                      <InputLabel>
                        {Object.keys(files).length < maxImagesUpload ? (
                          <>
                            <PhotoCamera />
                            <Input
                              type="file"
                              style={{ display: 'none' }}
                              inputProps={{
                                accept: 'image/*',
                              }}
                              onChange={handleOnAddImage}
                            />
                          </>
                        ) : (
                          <NoPhotography />
                        )}
                      </InputLabel>
                    </Box>
                    {Object.keys(files).map((fileKey) => (
                      <Box
                        key={fileKey}
                        style={{
                          position: 'relative',
                          width: '70px',
                          margin: '0 10px',
                        }}
                      >
                        <IconButton
                          aria-label="delete image"
                          style={{
                            position: 'absolute',
                            top: -10,
                            right: -10,
                            color: '#aaa',
                            zIndex: 100,
                          }}
                          onClick={() => handleOnRemoveImage(fileKey)}
                        >
                          <Cancel />
                        </IconButton>
                        <Image src={URL.createObjectURL(files[fileKey])} width="50" height="50" />
                      </Box>
                    ))}
                  </Box>
                </ListItem>
              </List>
              {submitBtn()}
            </Box>
          )
        }

        if ((lessonKarte.status as LessonKarteStatus) === LessonKarteStatus.Expired || lessonKarte.time === 0) {
          return (
            <>
              <Box>この日はお休みでした</Box>
              {lessonKarte.comment && (
                <>
                  <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold', fontSize: '0.9rem' }}>
                    トレーナーからのコメント
                  </Typography>
                  <Comment>{lessonKarte.comment}</Comment>
                </>
              )}
              {isStillEditable && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: 2,
                  }}
                >
                  <Button onClick={() => setIsEditMode(true)}>編集</Button>
                </Box>
              )}
            </>
          )
        }

        if (
          [LessonKarteStatus.Posted, LessonKarteStatus.Checked, LessonKarteStatus.Commented].includes(
            lessonKarte.status as LessonKarteStatus
          )
        ) {
          return (
            <Box sx={{ bgcolor: '#FFF', p: 1 }}>
              <List>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary={'学習時間: ' + lessonKarte.time + '分'} />
                </ListItem>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary="進捗: " />
                  <Box component="p">
                    <BreakLineBox>{lessonKarte.progress ? lessonKarte.progress : '（記載なし）'}</BreakLineBox>
                  </Box>
                </ListItem>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary={'子供の様子: ' + conditionEmoji(lessonKarte.condition)} />
                  <ListItemText primary={lessonKarte.detailedCondition} />
                </ListItem>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary={'お母さん／お父さんの様子: '} />
                  <Box component="p">{lessonKarte.parentCondition}</Box>
                </ListItem>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary="困りごと: " />
                  <Box component="p">
                    <BreakLineBox>{lessonKarte.problems ? lessonKarte.problems : '（記載なし）'}</BreakLineBox>
                  </Box>
                </ListItem>
                <ListItem divider sx={{ display: 'block', px: 0 }}>
                  <ListItemText primary="ファイル: " />
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {Object.keys(files).map((fileKey) => (
                      <Box
                        key={fileKey}
                        style={{
                          position: 'relative',
                          width: '70px',
                          margin: '0 10px',
                        }}
                      >
                        <Image
                          src={URL.createObjectURL(files[fileKey])}
                          width="50"
                          height="50"
                          onClick={() => setSelectedFileKey(fileKey)}
                        />
                      </Box>
                    ))}
                  </Box>
                </ListItem>
              </List>
              {isStillEditable && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: 2,
                  }}
                >
                  <Button onClick={() => setIsEditMode(true)}>編集</Button>
                </Box>
              )}
            </Box>
          )
        }

        return <Box></Box>
      })()}
      <ImageModal file={selectedFileKey === null ? null : files[selectedFileKey]} handleClose={handleModalClose} />
    </Box>
  )
}

type karteFormValues = {
  sorobanDones: number[]
  anzanDones: number[]
  progress: string
  time: string
  condition: string
  detailedCondition: string
  parentCondition: string
  problems: string
}

const karteHead = {
  fontSize: '0.8rem',
  fontWeight: 'bold',
  ml: '0.5rem',
}
