import { Box, Divider, Typography } from '@mui/material'
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import Link from 'next/link'
import React, { useContext, useEffect } from 'react'

import { Comment } from '@/components/common/Comment'
import { axiosContext } from '@/context/axios'
import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'

type Schedule = {
  trainer: string
  lessonAt: string
  meetingLink: string
}
export const IndividualLessonKarteContainer: React.VFC<{
  karte: IndividualLessonKarte
}> = ({ karte }) => {
  const { axiosInstance } = useContext(axiosContext)

  const [schedule, setSchedule] = React.useState<Schedule>()
  useEffect(() => {
    if (karte.scheduleId !== null) {
      const fetchData = async () => {
        const res = await axiosInstance.get(`/api/individual_lesson_schedule/${karte.scheduleId}`)
        setSchedule(res.data)
      }
      fetchData()
    }
  }, [])

  const [appHeight, setAppHeight] = React.useState(window.innerHeight - 210)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight - 210)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight - 210)
  })

  return (
    <Box className={'scrollableY'} sx={{ maxHeight: `${appHeight}px` }}>
      <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 1 }}>
        個別指導
      </Typography>
      {schedule && (
        <>
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem', color: '#8fc320' }}>
            レッスン日時
          </Typography>
          <Typography variant="body1">{format(new Date(schedule.lessonAt), 'yyyy年MM月dd日 HH:mm:ss')}</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem', mt: 1, color: '#8fc320' }}>
            担当トレーナー
          </Typography>
          <Typography variant="body1">{schedule.trainer}</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem', mt: 1, color: '#8fc320' }}>
            レッスンリンク
          </Typography>
          <Typography variant="body1" sx={{ color: 'blue' }}>
            <Link href={schedule.meetingLink}>
              <a target="_blank" rel="noopener noreferrer">
                {schedule.meetingLink}
              </a>
            </Link>
          </Typography>
        </>
      )}
      <Box>
        {karte.doneComment ? (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
              今日やったこと
            </Typography>
            <Comment>{karte.doneComment}</Comment>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, fontSize: '0.9rem' }}>
              今日できたこと
            </Typography>
            <Comment>{karte.achievedComment}</Comment>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 3, fontSize: '0.9rem' }}>
              次週の目標
            </Typography>
            <Comment>{karte.nextGoalComment}</Comment>
          </>
        ) : (
          <Typography variant="body1" sx={{ mt: 2 }}>
            まだコメントはありません。
          </Typography>
        )}
      </Box>
    </Box>
  )
}
