import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

import { Comment } from '@/components/common/Comment'
import { RestKarte } from '@/models/RestKarte'

const animation = `
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`

const FloatingBox = styled(Box)(`
  ${animation}
  animation: float 5s ease-in-out infinite;
`)

const animation2 = `
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }
`

const PulsatingBox = styled(Box)(`
  ${animation2}
  animation: pulsate 5s ease-in-out infinite;
`)

export const RestKarteContainer: React.VFC<{
  karte: RestKarte
}> = ({ karte }) => {
  const [appHeight, setAppHeight] = React.useState(window.innerHeight - 210)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight - 210)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight - 210)
  })

  return (
    <Box className={'scrollableY'} sx={{ maxHeight: `${appHeight}px`, padding: '6px, 3px' }}>
      <Box
        sx={{
          bgcolor: '#FFF',
          borderRadius: '12px',
          py: 10,
          mt: 12,
          textAlign: 'center',
          position: 'relative',
          boxShadow: '1px 2px 2px rgba(68, 155, 0, 0.2)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-70px',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            bgcolor: '#FFF',
            borderRadius: '120px',
            width: '120px',
            pt: 2,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: '56%',
              transform: 'translate(-50%, 0%)',
              top: '-65px',
            }}
          >
            <img src="/images/one_day_off_text.svg" />
          </Box>
          <FloatingBox>
            <img src="/images/apupu-child.svg" />
          </FloatingBox>
          <PulsatingBox>
            <img src="/images/apupu-child-shadow.svg" />
          </PulsatingBox>
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: '#449B00',
            fontWeight: 700,
            fontSize: '1.2rem',
            my: 2,
            p: 1,
            bgcolor: '#F0FDE6',
          }}
        >
          今日はおやすみです
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem', my: 2 }}>
          しっかりリフレッシュして、
          <br />
          また明日からチャレンジしていきましょう！
        </Typography>
      </Box>
      {karte.comment != null && (
        <Box>
          <Typography variant="body1" sx={{ fontSize: '1rem', mb: 1 }}>
            トレーナーからのコメント
          </Typography>
          <Comment>{karte.comment}</Comment>
        </Box>
      )}
    </Box>
  )
}
