import { css } from '@emotion/react'
import React from 'react'

import { CheckColor } from '@/models/Challenge'

export const ChallengeCheckbox: React.FC<{
  checked: boolean
  color: CheckColor
}> = ({ checked, color }) => {
  return (
    <label css={checkboxLabelStyle}>
      {checked ? (
        <img src={`/images/checkbox_${color}_on.svg`} alt="On" css={checkboxImageStyle} />
      ) : (
        <img src={`/images/checkbox_${color}_off.svg`} alt="Off" css={checkboxImageStyle} />
      )}
    </label>
  )
}

const checkboxLabelStyle = css`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
`

const checkboxImageStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
`
