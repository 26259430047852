import { LoadingButton } from '@mui/lab'
import React, { useState } from 'react'

export const SubmitButtonWithEvent: React.FC<{
  onClick: (e: React.MouseEvent) => void
}> = ({ onClick }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = async (e: React.MouseEvent) => {
    setLoading(true)
    await onClick(e)
    setLoading(false)
  }

  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      color="primary"
      onClick={(e) => {
        handleClick(e)
      }}
    >
      投稿
    </LoadingButton>
  )
}
