import { Typography } from '@mui/material'
import React from 'react'

export const BreakLineBox: React.FC = ({ children }) => {
  return (
    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
      {children}
    </Typography>
  )
}
