import { css } from '@emotion/react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, Modal, Typography } from '@mui/material'
import React from 'react'

import { ChallengeCheckbox } from '@/components/common/ChallengeCheckbox'
import { Challenge, CheckColor } from '@/models/Challenge'

const styleModalWrapper = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: '#FFF',
  boxShadow: 24,
  p: 0,
  borderRadius: '5px',
}

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '97%',
  height: '60%',
  maxHeight: '15rem',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  borderRadius: '5px',
  overflow: 'auto',
}

const listItemStyle = css({
  fontSize: '14px',
  alignItems: 'flex-start',
  mb: 2,
  borderRadius: '4px',
  padding: '2px 7px',
  boxShadow: '0px 2.5px 4px rgb(0 0 0 / 9%)',
  marginBottom: '6px',
})

const border = {
  green: css({
    border: '1px solid #6DC344',
  }),
  orange: css({
    border: '1px solid #FF8717',
  }),
}

const disabledStyle = (disabled: boolean) => {
  if (disabled) {
    return css`
      border: none;
      color: #7b7b7b;
      background-color: #f5f5f5;
      box-shadow: inset 0px 2.5px 5px -2px rgba(13, 13, 13, 0.09);
    `
  } else {
    return css``
  }
}

export const ChallengeList = (props: Props) => {
  const pageColor = props.color === 'green' ? '#449B00' : '#FF8717'
  const [openModal, setOpenModal] = React.useState<Challenge | null>(null)
  const handleOpenModal = (challenge: Challenge) => setOpenModal(challenge)
  const handleCloseModal = () => setOpenModal(null)

  const [openMovieModal, setOpenMovieModal] = React.useState<Challenge | null>(null)
  const handleOpenMovieModal = (challenge: Challenge) => setOpenMovieModal(challenge)
  const handleCloseMovieModal = () => setOpenMovieModal(null)
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', px: 1 }}>
      {props.tasks.map((value) => {
        const checked = props.dones.indexOf(value.id) !== -1

        return (
          <ListItem
            key={value.id}
            disablePadding
            disableGutters
            css={[listItemStyle, border[props.color], disabledStyle(!props.isEditable || checked)]}
          >
            <ListItemButton
              role={undefined}
              onClick={() => handleOpenModal(value)}
              dense
              sx={{
                px: 0,
                alignItems: 'flex-start',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '26px',
                }}
              >
                <ChallengeCheckbox checked={checked} color={props.color} />
              </ListItemIcon>
              <div css={css``}>
                {value.page ? (
                  <p
                    css={css`
                      margin: 0;
                      background-image: url('/images/page_bg_${props.color}.svg');
                      background-repeat: no-repeat;
                      font-weight: bold;
                      color: ${pageColor};
                      width: 32px;
                      height: 22px;
                      font-size: 0.8rem;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    {pageText(value.page)}
                  </p>
                ) : (
                  <p
                    css={css`
                      width: 32px;
                    `}
                  ></p>
                )}
              </div>
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '16.5rem',
                }}
              >
                {titleText(value)}
              </Typography>
            </ListItemButton>
          </ListItem>
        )
      })}
      <Modal
        open={openModal !== null}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {openModal !== null ? (
          <Box sx={styleModalWrapper}>
            <Box
              sx={{
                bgcolor: '#EBFFCE',
                p: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: '5px',
                  boxShadow: '0px 2.5px 4px rgba(0, 0, 0, 0.09)',
                  overflow: 'auto',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="body2"
                  component="p"
                  sx={{
                    fontSize: '1.2rem',
                    bgcolor: '#FFF',
                    px: 1,
                    py: 2,
                  }}
                >
                  {openModal.customeTitle ? openModal.customeTitle : openModal.title}
                </Typography>
                {pointText(openModal.point, openModal.customePoint) !== '' && (
                  <Box sx={{ bgcolor: '#FEFEE0' }}>
                    <div
                      css={css`
                        display: flex;
                        color: #fff;
                        background-color: #ff8c21;
                        width: 6.8rem;
                        padding: 0.2rem 0.5rem;
                        font-weight: bold;
                        border-bottom-right-radius: 5px;
                      `}
                    >
                      <img src={`/images/icon_light_white.svg`} />
                      ポイント
                    </div>
                    <Typography id="modal-modal-description" sx={{ px: 1, py: 2 }}>
                      {pointText(openModal.point, openModal.customePoint)}
                    </Typography>
                  </Box>
                )}
                {openModal.lessonMovie && (
                  <Box
                    onClick={() => handleOpenMovieModal(openModal)}
                    sx={{ display: 'flex', bgcolor: '#E73A2A', alignItems: 'center', py: '10px', px: 2 }}
                  >
                    <img src="/images/icon_youtube.svg" />
                    <Typography sx={{ color: '#FFF', ml: 2 }}>そろばん動画を見る</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ bgcolor: '#FFF', py: 2 }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => {
                  props.isEditable && props.handleClick(openModal.id)
                  handleCloseModal()
                }}
              >
                <ChallengeCheckbox checked={props.dones.indexOf(openModal.id) !== -1} color={props.color} />
                <Typography
                  sx={{ ml: 1, fontSize: '1.3rem', fontWeight: 'bold', ...(!props.isEditable && { opacity: 0.3 }) }}
                >
                  達成できた
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Modal>
      <Modal
        open={openMovieModal !== null}
        onClose={handleCloseMovieModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <iframe
            width="100%"
            height="100%"
            src={openMovieModal?.lessonMovie}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Modal>
    </List>
  )
}

const titleText = (challenge: Challenge) => {
  return challenge.customeTitle ? challenge.customeTitle : challenge.title
}

const pageText = (page: number | null) => {
  return page ? `P${page}` : ''
}

const pointText = (point: string | undefined, customePoint: string | undefined) => {
  if (customePoint) {
    return customePoint
  }
  return point ? point : ''
}

type Props = {
  tasks: Array<Challenge>
  dones: number[]
  handleClick: (value: number) => void
  isEditable: boolean
  color: CheckColor
}
