import { Box, Modal } from '@mui/material'
import Image from 'next/image'
import React from 'react'

export const ImageModal: React.VFC<{
  file: File | null
  handleClose: () => void
}> = ({ file, handleClose }) => {

  return (
    <Modal
      open={file !== null}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          bgcolor: 'background.paper',
          p: 1,
        }}
      >
        { file && <Image src={URL.createObjectURL(file)} width="100%" height="100%" layout="responsive" objectFit="contain" /> }
      </Box>
    </Modal>
  )
}
