import { Box } from '@mui/material'
import React from 'react'

import { BreakLineBox } from '@/components/common/BreakLineBox'

const style = {
  borderRadius: '3px',
  border: '1px solid #ad9b3b',
  backgroundColor: '#fffae3',
  p: 1,
  margin: '1px',
}

export const Comment: React.FC = ({ children }) => {
  return (
    <Box sx={style}>
      <BreakLineBox>{children}</BreakLineBox>
    </Box>
  )
}
